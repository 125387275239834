<template>
  <div class="row">
    <div>
      <button class="btn btn-success" @click="print">Печать</button>
    </div>
   
    <div>
<!--      <iframe :src="'/api/journal/export/' + groupId" width="1500px" height="1000px"  name="printf">

      </iframe>  -->
      <iframe src="javascript:void(0);" width="1500px" height="1000px"  name="printf" id="iiif">

      </iframe>
    </div>

  </div>
</template>
<style>
.dropped td {
  background-color: #F64E60;
}
</style>
<script>

import ApiService from "@/common/api.service";
export default {
  name: "GroupJournalExport",
  components: {
  },
  /*props:{
    groupId:String
  },*/
  data() {
    return {
      groupId:this.$route.params.groupId,
    };
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    reloadData(){
      //$(iframeSelector).contents().find('html').html(htmlContent);

      ApiService.querySecured("journal/export/" + this.groupId , {}).then(({data}) => {
        
        let iframe = document.getElementById('iiif');

        iframe.contentWindow.document.open();
        iframe.contentWindow.document.write(data);
        iframe.contentWindow.document.close();
      }).catch(({response}) => {
        console.log(response);
      });
      
      
    },
    print(){
      window.frames["printf"].focus();
      window.frames["printf"].print();
    }
  }
};
</script>
